exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-car-seat-upholstery-js": () => import("./../../../src/pages/car-seat-upholstery.js" /* webpackChunkName: "component---src-pages-car-seat-upholstery-js" */),
  "component---src-pages-complaints-js": () => import("./../../../src/pages/complaints.js" /* webpackChunkName: "component---src-pages-complaints-js" */),
  "component---src-pages-construction-paintwork-js": () => import("./../../../src/pages/construction-paintwork.js" /* webpackChunkName: "component---src-pages-construction-paintwork-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dry-mixes-js": () => import("./../../../src/pages/dry-mixes.js" /* webpackChunkName: "component---src-pages-dry-mixes-js" */),
  "component---src-pages-hydrophobic-chalk-js": () => import("./../../../src/pages/hydrophobic-chalk.js" /* webpackChunkName: "component---src-pages-hydrophobic-chalk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-paintwork-js": () => import("./../../../src/pages/paintwork.js" /* webpackChunkName: "component---src-pages-paintwork-js" */),
  "component---src-pages-polyurethane-sealant-js": () => import("./../../../src/pages/polyurethane-sealant.js" /* webpackChunkName: "component---src-pages-polyurethane-sealant-js" */),
  "component---src-pages-repair-putties-js": () => import("./../../../src/pages/repair-putties.js" /* webpackChunkName: "component---src-pages-repair-putties-js" */),
  "component---src-pages-road-paintwork-js": () => import("./../../../src/pages/road-paintwork.js" /* webpackChunkName: "component---src-pages-road-paintwork-js" */),
  "component---src-pages-sealant-js": () => import("./../../../src/pages/sealant.js" /* webpackChunkName: "component---src-pages-sealant-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-solvent-js": () => import("./../../../src/pages/solvent.js" /* webpackChunkName: "component---src-pages-solvent-js" */),
  "component---src-pages-tarpaulin-materials-js": () => import("./../../../src/pages/tarpaulin-materials.js" /* webpackChunkName: "component---src-pages-tarpaulin-materials-js" */),
  "component---src-pages-upholstery-for-furniture-js": () => import("./../../../src/pages/upholstery-for-furniture.js" /* webpackChunkName: "component---src-pages-upholstery-for-furniture-js" */)
}

