import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import { Link, useStaticQuery, graphql } from "gatsby"
import Share from "../components/Share"
import Ymaps from "../components/Ymaps"
import Logo from "../images/logo.png"

const FooterComponent = () => {

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      phone: allSanityPhone {
        edges {
          node {
            phone
          }
        }
      }
      mail: allSanityEMail {
        edges {
          node {
            name
          }
        }
      }
      address: allSanityAddress {
        edges {
          node {
            address
          }
        }
      }
      social: allSanitySocial {
        edges {
          node {
            name
          }
        }
      }
    }
  `)

  return (
      <footer className="footer is-shadow is-size-7">
        <div className="container">
          <Columns vcentered>
            <Column is3>
              <div className="icon-text">
            <span className="icon">
              <FontAwesomeIcon
                  icon="map-marker-alt"
                  size="sm"
                  color="#61a118"
              />
            </span>
                <span className="has-text-weight-bold">Наш адрес</span>
              </div>
              {data.address.edges.map((item, i) => (
                <p key={i} className="block mb-1">
                  {item.node.address}
                </p>
              ))}
              <div className="icon-text">
            <span className="icon">
              <FontAwesomeIcon icon="phone" size="sm" color="#61a118"/>
            </span>
                <span className="has-text-weight-bold">Номер телефона</span>
              </div>
              <p className="block has-text-link">
                {data.phone.edges.map((item, i) => (
                    <>
                      <a href={`tel:${item.node.phone}`}>{item.node.phone}</a>
                      <br/>
                    </>
                ))}
              </p>
            </Column>
            <Column is4>
              <div className="is-shadow">
                <Ymaps/>
              </div>
            </Column>
            <Column is1>
              <Link to="/" className="navbar-item" activeClassName="is-active">
                Главная
              </Link>
              <Link
                  to="/service"
                  className="navbar-item"
                  activeClassName="is-active"
              >
                Продукты и услуги
              </Link>
              <Link to="/about" className="navbar-item" activeClassName="is-active">
                О компании
              </Link>

              <Link to="/news" className="navbar-item" activeClassName="is-active">
                Новости
              </Link>
              <Link
                  to="/contact"
                  className="navbar-item"
                  activeClassName="is-active"
              >
                Контакты
              </Link>
            </Column>
            <Column is4 centered tmcentered>
              <Link to="/">
                <img className="logo" src={Logo} alt="Logo"/>
              </Link>
              <p className="is-size-7 mb-1">
                © {new Date().getFullYear()} Multi Business Company. Все права
                защищены. <br/>
                Сайт разработан{" "}
                <a
                    href="https://jahondev.uz/"
                    target="_blank"
                    className="has-text-link"
                >
                  www.jahondev.uz
                </a>
              </p>
              <Share/>
            </Column>
          </Columns>
        </div>
      </footer>
  )
}

export default FooterComponent
