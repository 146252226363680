import React from "react"
import { YMaps, Map, Placemark } from "react-yandex-maps"

const Ymaps = () => (
  <YMaps>
    <div className="mb-1">
      <Map
        width="100%"
        height="200px"
        defaultState={{
          center: [40.80059, 72.32327],
          zoom: 17,
        }}
      >
        <Placemark geometry={[40.80059, 72.32327]} />
      </Map>
    </div>
  </YMaps>
)

export default Ymaps
