import React from "react"
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  OKShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  FacebookIcon,
  EmailIcon,
  OKIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from "react-share"

const Share = () => {
  return (
    <div className="has-text-centered is-flex flex-wrap justify-content-center">
      <div className="mr-05">
        <FacebookShareButton url="https://www.uzmbc.uz/">
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
      </div>
      <div className="mr-05">
        <FacebookMessengerShareButton url="https://www.uzmbc.uz/">
          <FacebookMessengerIcon size={32} round={true} />
        </FacebookMessengerShareButton>
      </div>
      <div className="mr-05">
        <EmailShareButton url="https://www.uzmbc.uz/">
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
      </div>
      <div className="mr-05">
        <OKShareButton url="https://www.uzmbc.uz/">
          <OKIcon size={32} round={true} />
        </OKShareButton>
      </div>
      <div className="mr-05">
        <TelegramShareButton url="https://www.uzmbc.uz/">
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
      </div>
      <div className="mr-05">
        <TwitterShareButton url="https://www.uzmbc.uz/">
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </div>
      <div className="mr-05">
        <ViberShareButton url="https://www.uzmbc.uz/">
          <ViberIcon size={32} round={true} />
        </ViberShareButton>
      </div>
      <div className="mr-05">
        <VKShareButton url="https://www.uzmbc.uz/">
          <VKIcon size={32} round={true} />
        </VKShareButton>
      </div>
      <div className="mr-05">
        <WhatsappShareButton url="https://www.uzmbc.uz/">
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </div>
    </div>
  )
}

export default Share
