import React, { Component, createContext } from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import {
  faCalendarAlt,
  faStar,
  faGem,
  faGlobeAsia,
  faIndustry,
  faRobot,
  faPhone,
  faLocationArrow,
  faEnvelopeOpenText,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons"
import FooterComponent from "../components/Footer"

import "@fontsource/pt-sans";
import "@fontsource/inter";
import "../pages/mystyles.scss"

const ShoppingCart = createContext()

export const ShoppingConsumer = ShoppingCart.Consumer

library.add(
  fab,
  faCalendarAlt,
  faStar,
  faGem,
  faGlobeAsia,
  faIndustry,
  faRobot,
  faPhone,
  faLocationArrow,
  faEnvelopeOpenText,
  faFacebookF,
  faInstagram,
  faMapMarkerAlt
)
class Layout extends Component {
  constructor() {
    super()

    this.handleClickModal = this.handleClickModal.bind(this)

    this.state = {
      isModal: null,
      handleClick: this.handleClickModal,
    }
  }

  handleClickModal = id => {
    this.setState({ isModal: id })
  }

  render() {
    return (
      <ShoppingCart.Provider value={this.state}>
        <div className="site">
          <main className="site-content">{this.props.children}</main>
          <FooterComponent />
        </div>
      </ShoppingCart.Provider>
    )
  }
}

export default Layout
