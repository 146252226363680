import React, { Children } from "react"
import classNames from "classnames"

export const Columns = ({
  children,
  rowReverse,
  vcentered,
  centered,
  tcentered,
  tmcentered,
}) => (
  <div
    className={classNames("columns is-multiline", {
      "flex-row-reverse": rowReverse,
      "is-vcentered": vcentered,
      "is-centered": centered,
      "has-text-centered": tcentered,
      "has-text-centered-mobile": tmcentered,
    })}
  >
    {children}
  </div>
)

export const Column = ({
  children,
  is4,
  is5,
  is6,
  is7,
  is11,
  is12,
  offset1,
  offset2,
  offset3,
  centered,
  tmcentered,
  tcentered,
}) => (
  <div
    className={classNames("column", {
      "is-12": is12,
      "is-11": is11,
      "is-7": is7,
      "is-6": is6,
      "is-5": is5,
      "is-4": is4,
      "is-offset-1": offset1,
      "is-offset-2": offset2,
      "is-offset-3": offset3,
      "has-text-centered-desktop": centered,
      "has-text-centered": tcentered,
      "has-text-centered-mobile": tmcentered,
    })}
  >
    {children}
  </div>
)
